/* eslint-disable no-unused-vars */
/*
    This eslint rule is disabled as intended, because dayjs needs the imports
    but does not use them directly in the composable as default language.
*/
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import fr from 'dayjs/locale/fr';
import en from 'dayjs/locale/en';
import it from 'dayjs/locale/it';

dayjs.locale(de);

export default () => ({
    dayjs,
});
